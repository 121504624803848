import React, {Fragment} from 'react';
import {LogoCircular} from '../../components/icons/Logos'; 

const Process = props => {
    return (
        <div className={props.isActive}>
            <p className="avenir-uppercase-tiny">{props.step}</p>
            <h3 className="avenir-capitalise-small">{props.title}</h3>
            <p className="avenir-uppercase-tiny"><i>{props.duration}</i></p>
            <br />
            <p>{props.content}</p>
        </div>
    )
}
 
const BookingProcess = () => {
  return (
    <Fragment>
        <section className="booking-process section-padding">
            <p className="avenir-uppercase-tiny">Understanding</p>
            <h2 className="oriya-capitalise-small">The Audit Process</h2>
            <br />
            <LogoCircular />
            <div className="booking-process-steps">
                <Process 
                    step="Step 1"
                    title="Discovery Call"
                    duration="30-mins"
                    content={<>We take this time to <b>understand</b> your needs, efforts, and project timeline. We will provide <b>info</b> of our services and approach. Together, we define the <b>scope</b> of your proposal.</>}
                />
                <Process 
                    step="Step 2"
                    title="Proposal Review"
                    duration="2 - 4 days"
                    content={<>We send you a detailed <b>proposal</b>, the collaboration <b>agreement</b>, and your first <b>invoice</b> for review and signing. We will make sure that the proposal is finalised to everyone’s satisfaction.</>}
                />
                <Process 
                    step="Step 3"
                    title="Kick-Off Meeting"
                    duration="30-mins"
                    content={<>We review key <b>milestones</b> and <b>expectations</b>. We will also ensure we have the necessary <b>access</b> to proceed smoothly. Any remaining questions are addressed before starting the project.</>}
                />
                <Process 
                    step="Step 4"
                    title="Presentation"
                    duration="1-hour"
                    content={<>We present the audit <b>report</b> and walk you through our findings and the <b>remediation</b> steps. This completes the audit phase, and we can discuss potential <b>next steps</b> moving forward.</>}
                />
            </div>
            <br />
        </section>
        <hr />
    </Fragment>
  );
}

export default BookingProcess;
