import React from 'react';
import {
  WordpressLogo,
  SquarespaceLogo,
  WebflowLogo,
  ShopifyLogo,
  FigmaLogo,
  ReactLogo,
  VueLogo,
  WeeblyLogo
} from '../../components/icons/Logos'; 
 
const HomeSupportedTech = () => {
  return (
    <section className="supported-tech-section section-padding">
      <div className="section-heading">
        <p className="avenir-uppercase-tiny">Technology & Platforms We Work With</p>
        <br />
        <h2 className="oriya-uppercase-medium">
          Your Tools, Our Expertise
        </h2>
        <br />
        <p>
          From WordPress to React, Shopify to Squarespace, we pride ourselves on our flexibility and expertise in navigating a diverse range of technologies. To us, accessibility is not about reinventing the wheel — it is about enhancing what is already there. We work with (but are not limited to) the following technology and platform:
        </p>
      </div>
      <div className="spotlight-section">
          <WordpressLogo />
          <WebflowLogo />
          <ShopifyLogo />
          <SquarespaceLogo />
          <FigmaLogo />
          <WeeblyLogo />
          <ReactLogo />
          <VueLogo />
      </div>
    </section>
  );
}

export default HomeSupportedTech;
