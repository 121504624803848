import React, {Fragment} from 'react';
import { Helmet } from "react-helmet-async";
import HomeHeader from './home/Header';
import HomeTicker from './home/Ticker';
import HomeServices from './home/Services';
import HomeLaws from './home/Laws';
import HomeSupportedTech from './home/SupportedTech';
import CTA from '../components/CTA';
 
const Home = () => {
  return (
    <Fragment>
      <Helmet>
          <title>Hello Accessible - Digital Accessibility Consulting Firm | Vancouver</title>
      </Helmet>
      <HomeHeader />
      <HomeTicker />
      <hr />
      <HomeServices />
      <hr />
      <HomeLaws />
      <hr />
      <HomeSupportedTech />
      <hr />
      <CTA />
    </Fragment>
  );
}

export default Home;
