import React from 'react';
import CTAButton from './CTAButton';

const CTA = () => {
    return (
      <section className="cta-section" id="cta" role="contentinfo">
        <div>
          <video loop autoPlay muted playsInline aria-hidden="true" tab-index="-1">
            <source src="../../assets/stock-vid-3-edited.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <h3 className="oriya-capitalise-small">Unlock Your Full Market with Accessibility</h3>
          <br />
          <p>
            In today’s diverse digital landscape, accessibility isn’t just a feature — it’s a gateway to potential customers in your market that you are missing out on. Speak to our expert today. In our 30 minute consultation, we will learn about your business's existing accessibility status and explore our path forward together.
          </p>
          <br />
          <CTAButton />
        </div>
      </section>
    );
  }

export default CTA;