import React, {Fragment} from 'react';
import {IconWeb, IconApp, IconDesign, IconMobile} from '../../components/icons/Icons';
 
const Audits = () => {
  return (
    <Fragment>
      <hr />
      <section className="types-section section-padding">
          <div className="section-heading">
            <p className="avenir-uppercase-tiny">Tailored to your needs</p>
            <h2 className="oriya-capitalise-small">Types of Audits</h2>
          </div>
          <br />
          <div className="types-content-container">
            <div></div>
            <div className="types-content-section">
              <div className="types-content-item">
                <p className="avenir-uppercase-tiny"><IconWeb /></p>
                <h3 className="avenir-capitalise-small">Websites</h3>
                <p className="avenir-uppercase-tiny">E.g. Corporate / E-Commerce Sites</p>
                <br />
                <p>
                  Catered to all types of websites, our manual audits identify barriers that could prevent users with disabilities from engaging with your site. We prioritised recommendations to help you meet compliance standards like WCAG or ADA. 
                </p>
              </div>
              <div className="types-content-item">
                <p className="avenir-uppercase-tiny"><IconApp /></p>
                <h3 className="avenir-capitalise-small">Software</h3>
                <p className="avenir-uppercase-tiny">E.g. SaaS / Enterprise Software</p>
                <br />
                <p>
                  Targeting complex software architecture and component libraries, our accessibility audits evaluate dynamic functionality, workflows, and interactions to uncover barriers faced by users with disabilities.
                </p>
              </div>
              <div className="types-content-item">
                <p className="avenir-uppercase-tiny"><IconMobile /></p>
                <h3 className="avenir-capitalise-small">Mobile Apps</h3>
                <p className="avenir-uppercase-tiny">E.g. iOS / Android Applications</p>
                <br />
                <p>
                  Our audits evaluate key areas of usability in your mobile app such as touch targets, gestures, screen reader compatibility, color contrast, and focus management to uncover barriers for users with disabilities. Let us help make your app accessible on every device.
                </p>
              </div>
              <div className="types-content-item">
                <p className="avenir-uppercase-tiny"><IconDesign /></p>
                <h3 className="avenir-capitalise-small">Designs</h3>
                <p className="avenir-uppercase-tiny">E.g. Wireframe / Prototype Mockups</p>
                <br />
                <p>
                  Prevention is better than cure. Projects are cheaper when accessibility starts at the design stage before development. We ensure contrast, typography, and interactions in your Figma or Sketch files meet accessibility standards, creating a foundation for inclusive experiences.
                </p>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </Fragment>
  );
}

export default Audits;
