import React, {Fragment} from 'react';
import {Helmet} from "react-helmet-async";
import Form from './contact/Form';
import Sidebar from './contact/Sidebar';

const Contact = () => {
  return (
    <Fragment>
        <Helmet>
            <title>Contact Hello Accessible - Digital Accessibility Consulting Firm | Vancouver</title>
        </Helmet>
        <section className="contact-page" role="contentinfo" id="content">
            <div className="contact-right-column section-padding">
                <h1 className="oriya-uppercase-medium">Reach out to our team</h1>
                <h2 className="avenir-uppercase-tiny">Let's make a difference together</h2>
                <Form />
            </div>
            <Sidebar />
        </section>
        <hr />
        <section className="contact-map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21803.13424774351!2d-123.1460601295417!3d49.30110261379473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486718636aadcf3%3A0x7a655bbf397452c8!2s1575%20W%20Pender%20St%2C%20Vancouver%2C%20BC%20V6G%202T1%2C%20Canada!5e0!3m2!1sen!2smy!4v1729328915764!5m2!1sen!2smy" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Map of Hello Accessible's office" />
        </section>
    </Fragment>
  );
}

export default Contact;
