import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Logo} from './icons/Logos';
import {IconOpenLink} from './icons/Icons';

const Navigation = () => {
  const [click, setClick] = useState(false);
  const handleMobileClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const navigate = useNavigate();
  const handleButtonClick = () => navigate('/contact');

  return (
    <nav id="navigation" aria-expanded={click ? "true" : "false"}>
      <NavLink to='/' className="nav-left-column" aria-label="Visit homepage via site logo">
        <Logo />
      </NavLink>
      <ul className={`nav-centre-column ${click ? "mobile-nav-visible" : "mobile-nav-hidden"}`}>
        <li onClick={closeMobileMenu}><NavLink to="/">Home</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/about">About</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/services">Services</NavLink></li>
        <li onClick={closeMobileMenu}><a href="https://blog.helloaccessible.com/" target="_blank" aria-label="Link opens in a new tab" className="open-link-new-tab">Blog <IconOpenLink /></a></li>
        <li onClick={closeMobileMenu}><NavLink to="/contact">Contact</NavLink></li>
      </ul>
      <button onClick={handleButtonClick} className="button nav-right-column">Get Started</button>
      <button
        role="navigation"
        aria-controls="menu"
        onClick={handleMobileClick}
        className={`mobile-nav-indicator icon-menu ${click ? "mobile-nav-visible-button" : ""}`}
      > 
        <p>Menu</p>
        <span />
        <span />
        <span />
      </button>
    </nav>
  );
}

export default Navigation;
