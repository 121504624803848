import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import Navigation from './components/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Footer from './components/Footer';
import ResetScroll from './utilities/ResetScroll';
import Accessibility from './pages/Accessibility';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <ResetScroll />
        <div className="screen-reader-only" aria-label="Skip to section">
          <a href="#navigation" className="skip-link">Skip to navigation</a>
          <a href="#content" className="skip-link">Skip to main content</a>
          <a href="#footer" className="skip-link">Skip to footer</a>
        </div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/services" element={<Services />}/>
          <Route path="/services/:tab" element={<Services />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/terms" element={<Terms />}/>
          <Route path="/privacy" element={<Privacy />}/>
          <Route path="/accessibility" element={<Accessibility />}/>
        </Routes>
        <Footer />
        <ScrollToTop smooth color="#000000" />
      </Router>
    </HelmetProvider>
  );
}

export default App;
