import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
 
const FAQ = () => {
    const faqItems = [
        {
            question: "Why isn’t automated accessibility testing enough?",
            answer: `
                While automated tools are a great starting point for identifying accessibility issues, they cannot capture everything important.
                Automated tools fall short in evaluating user contextual experience, context, and interactions that impact real people. 
                A thorough manual review by accessibility experts ensures your digital products are truly usable and compliant for all users.
            `,
        },
        {
            question: "What is the ideal frequency for conducting audits?",
            answer: `
                At a minimum, your business should conduct an accessibility audit annually.
                There are few caveats that we consider: If your website undergoes frequent changes or if you are in industries with high compliance risks, such as finance, healthcare or government, aim to conduct an accessibility audit every 3 - 6 months. 
                If there are upcoming major redesigns or launches, always perform a comprehensive audit before and after significant changes or migrations. 
                Finally, for high-traffic digital assets, we recommend pairing regular audits with continuous monitoring baked into your system to arrest issues between audits.
                How often audits are conducted depends on the size of your organization, the nature of your digital assets, and the rate of updates or changes to your content.
            `,
        },
    ];

  return (
    <section className="booking-process section-padding">
        <p className="avenir-uppercase-tiny">Let's address these</p>
        <h2 className="oriya-capitalise-small">Frequently Asked Questions</h2>
        <br /><br />
        <Accordion allowZeroExpanded allowMultipleExpanded>
            {faqItems.map((item, index) => (
                <AccordionItem key={index}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {item.question}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>{item.answer}</p>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
        </Accordion>
        <br />
    </section>
  );
}

export default FAQ;
